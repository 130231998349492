<template>
  <b-modal v-model="showModal" ref="modalSelectProduct" centered @hidden="hide">
    <template #modal-header>Please Select Product(s)</template>
    <b-row>
      <b-col>
        <InputSelect
          title="Level Category 1"
          name="type"
          isRequired
          class="mt-2"
          v-model="selected1"
          noPleaseSelect
          v-bind:options="list_ah1"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 1)"
        />
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 2"
          name="type"
          isRequired
          class="mt-2"
          v-model="selected2"
          v-bind:options="list_ah2"
          noPleaseSelect
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 2)"
        />
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 3"
          noPleaseSelect
          name="type"
          isRequired
          class="mt-2"
          v-model="selected3"
          v-bind:options="list_ah3"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 3)"
        />
      </b-col>
      <b-col>
        <InputSelect
          title="Level Category 4"
          name="type"
          isRequired
          class="mt-2"
          v-model="selected4"
          noPleaseSelect
          v-bind:options="list_ah4"
          valueField="ah_id"
          textField="ah_name"
          @onDataChange="selectCategory($event, 4)"
        />
      </b-col>
    </b-row>
    <b-input-group class="mt-2">
      <b-form-input
        placeholder="Search Product Name"
        v-model="filter.get_product.search"
        @keyup.enter="searchCouponList"
      ></b-form-input>
      <b-input-group-append>
        <b-input-group-text>
          <b-iconstack
            font-scale="2"
            type="submit"
            @click.prevent="searchCouponList"
          >
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-text>
      </b-input-group-append>
    </b-input-group>
    <b-table
      striped
      hover
      responsive
      :items="productLists"
      :fields="columns"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      class="mt-2"
    >
      <template v-slot:cell(name)="data">
        <div class="text-left">
          <p class="m-0 text-black" v-if="data.item.name">
            {{ data.item.name }}
          </p>
          <div>
            {{ data.item.article_no }}
            <span class="article-type">{{ data.item.article_type }}</span>
          </div>
        </div>
      </template>
      <template v-slot:cell(imge_url)="data">
        <div class="position-relative picture-text pic-table">
          <div
            v-if="data.item.imge_url"
            class="square-box b-contain pic-box"
            v-bind:style="{
              'background-image': 'url(' + data.item.imge_url + ')',
            }"
          ></div>
          <div v-else>-</div>
        </div>
      </template>
      <template v-slot:cell(price)="data">
        <p class="m-0 price-text" v-if="data.item.price">
          {{ data.item.price | numeral("0,0.00") }}
        </p>
        <p v-else class="m-0 price-text">-</p>
      </template>
      <template v-slot:cell(is_check)="data">
        <div>
          <b-form-checkbox-group v-model="selected">
            <b-form-checkbox
              :key="data.item.id"
              :value="data.item.id"
              @change="getChk($event, data.item)"
              :disabled="valid == 1 ? data.item.is_check : false"
            >
            </b-form-checkbox>
          </b-form-checkbox-group>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-start mb-2 mb-sm-0"
      >
        <p class="mb-0 p-gray">
          Showing {{ showing === 1 ? showing : showing + 1 }} to
          {{ showingTo }} of {{ rows }} entires
        </p>
      </b-col>
      <b-col
        cols="12"
        md="6"
        class="form-inline justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="filter.get_product.length"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
          class="m-md-0"
          @change="pagination"
        ></b-pagination>
      </b-col>
    </b-row>
    <template #modal-footer>
      <b-row>
        <b-col class="text-left">
          <b-button @click.prevent="hide(1)" class="border-btn"
            >Cancel</b-button
          >
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button class="submit-btn" @click.prevent="submit">Save</b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
export default {
  components: {
    InputSelect,
  },
  props: {
    list_ah1: {
      required: true,
      type: Array,
    },
    list_ah2: {
      required: true,
      type: Array,
    },
    list_ah3: {
      required: true,
      type: Array,
    },
    list_ah4: {
      required: true,
      type: Array,
    },
    columns: {
      required: true,
      type: Array,
    },
    productLists: {
      required: true,
      type: Array,
    },
    rows: {
      required: true,
      type: Number,
    },
    id: {
      required: true,
      type: String,
    },
    filter: {
      required: true,
      type: Object,
    },
    valid: {
      required: false,
      type: Number,
    },
    isLoading: {
      required: false,
      type: Boolean,
    },
    productListSelect: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      showModal: false,
      selected1: 0,
      selected2: 0,
      selected3: 0,
      selected4: 0,
      selected: [],
      isBusy: false,
      showing: 1,
      showingTo: 5,
      productItemSelect: [],
      productDelete: [],
      productSelect: [],
      itemSelectChildren: [],
      currentPage: 1,
    };
  },
  async mounted() {
    if (this.productLists?.length > 0) {
      await this.checkedSelect();
    }
  },
  watch: {
    productLists: function () {
      if (this.productLists?.length > 0) {
        this.productSelect = this.selected;
        this.checkedSelect();
      }
    },
  },
  methods: {
    async show() {
      this.showModal = true;
      this.itemSelectChildren = [];
      this.selected = [];
      this.$emit(
        "getProductAgain",
        Array.from(new Set(this.productListSelect))
      );
    },
    hide(status) {
      this.selected1 = 0;
      this.selected2 = 0;
      this.selected3 = 0;
      this.selected4 = 0;
      this.noPleaseSelect = true;
      this.showModal = false;
    },
    searchCouponList(val) {
      this.$emit("searchProduct", {
        filter: this.filter.get_product.search,
        page: this.filter.get_product.start,
        productSelect: this.selected,
      });
    },
    pagination(value) {
      this.filter.get_product.start = value * 5 - 5;
      if (value === 1) {
        this.showing = this.filter.get_product.start;
        this.showingTo = this.filter.get_product.length;
      } else {
        this.showing =
          value * this.filter.get_product.length -
          this.filter.get_product.length;
        this.showingTo = this.showing + this.filter.get_product.length;
      }
      this.$emit("filterpage", {
        page: this.filter.get_product.start,
        productSelect: this.selected,
      });
    },
    getChk(checked, item) {
      if (checked) {
        if (this.productSelect?.length > 0) {
          let filterProductOut = this.productSelect.includes(item.id);
          if (!filterProductOut) {
            this.productSelect = [...checked];
            this.itemSelectChildren.push(item);
          } else {
            let positionArry = this.productSelect.indexOf(item.id);
            let deleteProduct = this.productSelect.splice(positionArry, 1);
            this.productDelete = [...deleteProduct, ...this.productDelete];

            let positionItem = this.itemSelectChildren.findIndex(
              (el) => el.id === item.id
            );
            this.itemSelectChildren.splice(positionItem, 1);
          }
        } else {
          this.itemSelectChildren.push(item);
          this.productSelect = [...checked];
        }
      }
    },
    checkedSelect() {
      for (const index in this.productLists) {
        if (this.productLists[index].is_check === 1) {
          this.selected.push(this.productLists[index].id);
          this.itemSelectChildren.push(this.productLists[index]);
        }
      }
    },
    submit() {
      this.productItemSelect = this.itemSelectChildren;
      let dataProduct = [];
      if (this.productListSelect.length > 0) {
        dataProduct = [...this.productSelect, ...this.selected];
      } else {
        dataProduct = this.selected;
      }
      this.$emit("product", {
        productSelect: dataProduct,
        productItemSelect: this.productItemSelect,
      });
      this.$emit("delete", this.productDelete);
      this.itemSelectChildren = [];
      this.hide();
    },
    selectCategory(value, no) {
      this.noPleaseSelect = false;
      this.filter.get_product[`ah${no}`] = value;
      this.$emit("searchCategory", {
        productSelect: this.selected,
        searchCategory: this.filter,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
  color: var(--font-color);
  font-weight: 600;
}
::v-deep .modal-dialog {
  max-width: 800px;
}
::v-deep .input-group-text {
  padding: 0;
  background-color: transparent;
}
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
  text-align: center;
}
::v-deep .modal-footer {
  justify-content: space-between;
}
.btn-save {
  color: #fff;
  background-color: var(--primary-color);
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
.btn-cancel {
  color: #fff;
  background-color: #39424e;
  width: 100%;
  border: none;
  padding: 5px 50px;
  border-radius: 0px;
}
</style>
