<template>
  <div>
    <b-container class="no-gutters bg-white px-2 px-md-3 pb-2 pb-md-3">
      <b-table
        ref="tableCustomerUseCoupon"
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(name)="data">
          <div class="text-left ml-2">{{ data.item.name }}</div>
        </template>
        <template v-slot:cell(redeemed_date_string)="data">
          <div>
            {{ data.item.redeemed_date_string }}
            <span class="time-color"
              >({{ $moment(data.item.redeemed_date).format("HH:mm:ss") }})</span
            >
          </div>
        </template>
        <template v-slot:cell(used_date_string)="data">
          <div v-if="!data.item.used_date">-</div>
          <div v-else>
            {{ data.item.used_date_string }}
            <span class="time-color"
              >({{ $moment(data.item.used_date).format("HH:mm:ss") }})</span
            >
          </div>
        </template>
        <template v-slot:cell(status_name)="data">
          <div v-if="!data.item.status_name" class="status-complete">
            Redeemed
          </div>
          <div v-if="data.item.status_name === 'Used'" class="status-complete">
            {{ data.item.status_name }}
          </div>
          <div
            v-if="
              data.item.status_name === 'Not Yet Used' ||
              data.item.status_name === 'Not Yet Used'
            "
            class="status-void"
          >
            {{ data.item.status_name }}
          </div>
          <div v-if="data.item.status_name === 'Expire'" class="status-cancel">
            {{ data.item.status_name }}
          </div>
        </template>
        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="handleChangeTake"
        :pageOptions="pageOptions"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    showing: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      toShow: this.showingTo,
      show: this.showing,
    };
  },
  methods: {
    handleChangeTake(value) {
      this.filter.page = 1;
      this.show = 1;
      this.filter.take = value;
      this.toShow = value;
      this.filter.redeem_date = null;
      this.filter.used_date = null;
      this.$emit("getPageOption", this.filter);
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.show = this.filter.page;
        this.toShow = this.filter.take;
      } else {
        this.show = value * this.filter.take - this.filter.take;
        this.toShow = this.show + this.filter.take;
      }
      this.$emit("getPage", this.filter);
    },
  },
};
</script>

<style lang="scss" scoped>
.time-color {
  color: #b5b5b5;
}
.status-complete {
  color: #00bd00;
}
.status-void {
  color: #f9b115;
}
.status-cancel {
  color: red;
}
</style>
